import React from "react";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import jizhi from "assets/img//jizhi.png";

function LevelPage() {
  return (
    <>
      <HeaderTitle title="Cơ chế thành viên" />
      <div className="page-container">
        <div className="level-page">
          <img src={jizhi} alt="" />
        </div>
      </div>
    </>
  );
}

export default LevelPage;
