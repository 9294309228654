import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import lhc from "assets/img/lhc.png";

export default function Profit() {
  const navigate = useNavigate();
  const {
    authStore: { order_random },
  } = useStores();

  return (
    <div className="list-wrap">
      <div className="winner-list-wrap">
        <div className="title">
          <div className="icon">
            <img src={lhc} alt="" />
          </div>
          <div className="text">Danh sách lợi nhuận</div>
        </div>
        <div className="winner-list ">
          <ul className="inner-wrap targetUp">
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyễn Quang Hải </div>
                <div className="account">Tên người dùng：uj1****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>35,017,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">DƯƠNG TUẤN ANH</div>
                <div className="account">Tên người dùng：vrs****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>26,015,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Phạm Quang Huy</div>
                <div className="account">Tên người dùng：brj****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>64,939,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyễn Quỳnh Nhi</div>
                <div className="account">Tên người dùng：nku****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>15,573,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Liên Thị Thanh Hương</div>
                <div className="account">Tên người dùng：9za****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>77,464,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyễn Thủy Tiên</div>
                <div className="account">Tên người dùng：8nz****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>79,641,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyễn văn sơn</div>
                <div className="account">Tên người dùng：tqb****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>47,628,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Hoàng Thái</div>
                <div className="account">Tên người dùng：d2k****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>86,679,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Lê Công Nhật</div>
                <div className="account">Tên người dùng：hkg****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>20,643,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyễn tuấn anh</div>
                <div className="account">Tên người dùng：p82****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>65,282,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Trần Đức Toản</div>
                <div className="account">Tên người dùng：pd9****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>63,726,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">DƯƠNG TUẤN ANH</div>
                <div className="account">Tên người dùng：fo2****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>36,555,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Bùi Kim Quý </div>
                <div className="account">Tên người dùng：8p8****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>13,122,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Phạm Văn Dũng</div>
                <div className="account">Tên người dùng：7rg****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>94,997,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Liên Thị Thanh Hương</div>
                <div className="account">Tên người dùng：jrm****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>1,888,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyễn Thị Khánh Linh</div>
                <div className="account">Tên người dùng：lbm****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>36,106,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyễn Hữu Thịnh</div>
                <div className="account">Tên người dùng：f0q****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>21,650,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyen Tien Phú</div>
                <div className="account">Tên người dùng：py3****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>39,683,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Lê Thùy Linh </div>
                <div className="account">Tên người dùng：bbr****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>84,030,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Mạc Hoàng Anh</div>
                <div className="account">Tên người dùng：s86****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>82,360,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Trương Kim Thành</div>
                <div className="account">Tên người dùng：0ck****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>86,630,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyễn Tuấn Anh </div>
                <div className="account">Tên người dùng：1qn****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>12,029,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Ma Lăng Khiêm</div>
                <div className="account">Tên người dùng：99x****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>91,971,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Lê Thùy Linh </div>
                <div className="account">Tên người dùng：xxl****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>90,438,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Hoàng Nhật Long</div>
                <div className="account">Tên người dùng：iqq****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>2,882,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">NGUYỄN HOÀNG LONG</div>
                <div className="account">Tên người dùng：r04****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>24,375,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Phạm Thị Ngọc</div>
                <div className="account">Tên người dùng：ke3****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>11,816,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">NGUYỄN THỊ BÍCH TUYỀN</div>
                <div className="account">Tên người dùng：04n****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>68,383,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Vương Việt Hoàng </div>
                <div className="account">Tên người dùng：gu0****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>17,275,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Lê Thị Ngọc Anh</div>
                <div className="account">Tên người dùng：qs0****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>52,958,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">NGUYỄN HOÀNG LONG</div>
                <div className="account">Tên người dùng：vev****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>62,853,000</span> VND
              </div>
            </li>
          </ul>
          <ul className="inner-wrap targetUp">
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyễn Quang Hải </div>
                <div className="account">Tên người dùng：uj1****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>35,017,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">DƯƠNG TUẤN ANH</div>
                <div className="account">Tên người dùng：vrs****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>26,015,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Phạm Quang Huy</div>
                <div className="account">Tên người dùng：brj****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>64,939,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyễn Quỳnh Nhi</div>
                <div className="account">Tên người dùng：nku****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>15,573,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Liên Thị Thanh Hương</div>
                <div className="account">Tên người dùng：9za****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>77,464,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyễn Thủy Tiên</div>
                <div className="account">Tên người dùng：8nz****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>79,641,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyễn văn sơn</div>
                <div className="account">Tên người dùng：tqb****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>47,628,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Hoàng Thái</div>
                <div className="account">Tên người dùng：d2k****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>86,679,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Lê Công Nhật</div>
                <div className="account">Tên người dùng：hkg****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>20,643,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyễn tuấn anh</div>
                <div className="account">Tên người dùng：p82****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>65,282,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Trần Đức Toản</div>
                <div className="account">Tên người dùng：pd9****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>63,726,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">DƯƠNG TUẤN ANH</div>
                <div className="account">Tên người dùng：fo2****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>36,555,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Bùi Kim Quý </div>
                <div className="account">Tên người dùng：8p8****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>13,122,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Phạm Văn Dũng</div>
                <div className="account">Tên người dùng：7rg****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>94,997,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Liên Thị Thanh Hương</div>
                <div className="account">Tên người dùng：jrm****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>1,888,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyễn Thị Khánh Linh</div>
                <div className="account">Tên người dùng：lbm****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>36,106,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyễn Hữu Thịnh</div>
                <div className="account">Tên người dùng：f0q****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>21,650,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyen Tien Phú</div>
                <div className="account">Tên người dùng：py3****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>39,683,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Lê Thùy Linh </div>
                <div className="account">Tên người dùng：bbr****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>84,030,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Mạc Hoàng Anh</div>
                <div className="account">Tên người dùng：s86****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>82,360,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Trương Kim Thành</div>
                <div className="account">Tên người dùng：0ck****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>86,630,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Nguyễn Tuấn Anh </div>
                <div className="account">Tên người dùng：1qn****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>12,029,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Ma Lăng Khiêm</div>
                <div className="account">Tên người dùng：99x****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>91,971,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Lê Thùy Linh </div>
                <div className="account">Tên người dùng：xxl****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>90,438,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Hoàng Nhật Long</div>
                <div className="account">Tên người dùng：iqq****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>2,882,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">NGUYỄN HOÀNG LONG</div>
                <div className="account">Tên người dùng：r04****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>24,375,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Phạm Thị Ngọc</div>
                <div className="account">Tên người dùng：ke3****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>11,816,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">NGUYỄN THỊ BÍCH TUYỀN</div>
                <div className="account">Tên người dùng：04n****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>68,383,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Vương Việt Hoàng </div>
                <div className="account">Tên người dùng：gu0****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>17,275,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">Lê Thị Ngọc Anh</div>
                <div className="account">Tên người dùng：qs0****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>52,958,000</span> VND
              </div>
            </li>
            <li className="winner-item">
              <div className="left">
                <div className="name">NGUYỄN HOÀNG LONG</div>
                <div className="account">Tên người dùng：vev****</div>
              </div>
              <div className="right">
                <span>+</span>
                <span>62,853,000</span> VND
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
