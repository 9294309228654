import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { PaymentApi } from "states/api/payment";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { paymentStatusText } from "_common/constants/statusType";
import { formatNumber } from "_common/utils/formatValue";

function BalanceDetailPage() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const id = params.get("id");

  const { data, isLoading } = useQuery(
    ["payment", "detail_balance", id],
    () => PaymentApi.getDetail({ id }),
    {
      staleTime: 300000,
      id,
    }
  );

  return (
    <>
      <HeaderTitle title="Chi tiết giao dịch" />
      <div className="container_main">
        <div className="histories ">
          <div className="main-info">
            <div className="item">
              <span className="label">Mã đơn giao dịch:</span>
              <span className="text">{data?.ref_no}</span>
            </div>
            <div className="item">
              <span className="label">Thời gian giao dịch:</span>
              <span className="text">
                {moment(data?.created_at).format("DD/MM/YYYY HH:mm:ss")}
              </span>
            </div>
            <div className="item">
              <span className="label">Phương thức giao dịch:</span>
              <span className="text">Thẻ ngân hàng</span>
            </div>
            <div className="item">
              <span className="label">Trạng thái giao dịch:</span>
              <span className="text">{paymentStatusText[data?.status]}</span>
            </div>
            <div className="item">
              <span className="label">Số tiền giao dịch:</span>
              <span className="text">{formatNumber(data?.final_total)}</span>
            </div>
            {data?.reason && (
              <div className="item">
                <span className="label">Lý do từ chối:</span>
                <span className="text text-red">{data?.reason}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BalanceDetailPage;
