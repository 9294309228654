import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import { Link, useNavigate } from "react-router-dom";
import { formatCurrency, formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { get } from "lodash";
import { PaymentApi } from "states/api/payment";

const WithdrawForm = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  const [amount, setAmount] = useState(0);
  const bank_id = useMemo(() => get(user, "banks[0].id"), [user?.banks]);

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      PaymentApi.deposit({
        params: {
          ...variables,
          bank_id,
          amount,
          bank_method: "banking",
          token_id: 3,
          type: "withdraw",
        },
      }),
    {
      onSuccess: (res) => {
        setAmount(0);
        queryClient.invalidateQueries(["get_profile"]);
        navigate(`/payment/deposit/success?money=${amount}`);
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleChangeInput = (event) => {
    const value = event?.target?.value;
    const format = value?.replaceAll(",", "")?.replaceAll(".", "");
    setAmount(Number(format));
  };

  const handleSubmit = () => {
    if (!bank_id)
      return NotificationManager.error("Vui lòng chọn ngân hàng rút tiền");
    if (amount < Number(general?.min_withdraw || 0))
      return NotificationManager.error(
        `Vui lòng nhập số tiền lớn hơn ${formatNumber(general?.min_withdraw)}`
      );
    if (amount > Number(general?.max_withdraw || 0))
      return NotificationManager.error(
        `Vui lòng nhập số tiền nhỏ hơn ${formatNumber(general?.max_withdraw)}`
      );
    onSubmit();
    return null;
  };

  return (
    <div className="withdrawal-page">
      <div className="top">
        <p>SỐ DƯ: {formatNumber(user_wallet?.amount)}</p>
      </div>
      <div className="name">Biệt danh người dùng: {user?.full_name} </div>
      <div className="adm-form withdrwalForm">
        <div className="adm-list adm-list-default">
          <div className="adm-list-body">
            <div className="adm-list-body-inner">
              <div className="adm-list-item adm-form-item adm-form-item-horizontal">
                <div className="adm-list-item-content">
                  <div className="adm-list-item-content-prefix">
                    <div className="label adm-form-item-label">
                      Hình thức thanh toán:
                    </div>
                  </div>
                  <div className="adm-list-item-content-main">
                    <div className="adm-form-item-child adm-form-item-child-position-normal">
                      <div className="adm-form-item-child-inner">
                        <div className="input adm-input">Thẻ ngân hàng</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="adm-list-item adm-form-item adm-form-item-horizontal">
                <div className="adm-list-item-content">
                  <div className="adm-list-item-content-prefix">
                    <div className="label adm-form-item-label">
                      Số tiền rút:
                    </div>
                  </div>
                  <div className="adm-list-item-content-main">
                    <div className="adm-form-item-child adm-form-item-child-position-normal">
                      <div className="adm-form-item-child-inner">
                        <div className="adm-input">
                          <input
                            inputMode="numeric"
                            pattern="[0-9]"
                            placeholder="Vui lòng nhập số tiền rút"
                            className="adm-input-element"
                            value={amount ? formatNumber(amount) : ""}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="adm-list-item adm-form-item adm-form-item-horizontal">
                <div className="adm-list-item-content">
                  <div className="adm-list-item-content-prefix">
                    <div className="label adm-form-item-label">
                      Số tài khoản:
                    </div>
                  </div>
                  <div className="adm-list-item-content-main">
                    <div className="adm-form-item-child adm-form-item-child-position-normal">
                      <div className="adm-form-item-child-inner">
                        <div className="input adm-input">
                          {get(user, "banks[0].bank_number_display")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="adm-list-item adm-form-item adm-form-item-horizontal">
                <div className="adm-list-item-content">
                  <div className="adm-list-item-content-prefix">
                    <div className="label adm-form-item-label">
                      Tên ngân hàng:
                    </div>
                  </div>
                  <div className="adm-list-item-content-main">
                    <div className="adm-form-item-child adm-form-item-child-position-normal">
                      <div className="adm-form-item-child-inner">
                        <div className="input adm-input">
                          {get(user, "banks[0].bank_name")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="adm-list-item adm-form-item adm-form-item-horizontal">
                <div className="adm-list-item-content">
                  <div className="adm-list-item-content-prefix">
                    <div className="label adm-form-item-label">
                      Tên chủ tài khoản:
                    </div>
                  </div>
                  <div className="adm-list-item-content-main">
                    <div className="adm-form-item-child adm-form-item-child-position-normal">
                      <div className="adm-form-item-child-inner">
                        <div className="input adm-input">
                          {get(user, "banks[0].bank_owner")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-btn">
        <button
          type="button"
          className={classNames("btn btn-sub", {
            active: Number(amount || 0) >= Number(general?.min_withdraw || 0),
          })}
          onClick={handleSubmit}
        >
          Rút tiền
        </button>
      </div>
    </div>
  );
});

export default WithdrawForm;
