import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { ProfileApi } from "states/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import classNames from "classnames";

const typeText = {
  full_name: "Sửa đổi họ tên thật",
  nick_name: "Sửa đổi họ tên thật",
};

const schema = yup
  .object({
    full_name: yup.string().required("Bắt buộc điền"),
    nick_name: yup.string().required("Bắt buộc điền"),
    sex: yup.string().required("Bắt buộc điền"),
  })
  .required();

const AboutPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet },
  } = useStores();
  const [params] = useSearchParams();
  const type = params.get("type");

  const { mutate, isLoading } = useMutation(
    (variables) => ProfileApi.update({ params: variables }),
    {
      onSuccess: (res) => {
        navigate("//member/information");
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(res?.msg || "Thao tác thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = () => {
    if (isLoading) return;
    trigger();
    const formValue = getValues();
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      mutate(formValue);
    }
  };

  useEffect(() => {
    if (!user) return;
    setValue("full_name", user?.full_name);
    setValue("nick_name", user?.nick_name);
    setValue("sex", user?.sex);
  }, [user]);

  return (
    <>
      <HeaderTitle
        title="Chuyển đường dây"
        isSave
        onSubmit={handleSubmit(onSubmit)}
      />
      <div className="update-info-container">
        <form className="form-action form-horizontal">
          {type === "full_name" && (
            <div
              className={classNames("form-item", {
                error: errors.full_name,
              })}
            >
              <div htmlFor="full_name" className="form_label">
                Họ tên mới
              </div>
              <div className="form_field">
                <input
                  className="field_control"
                  id="full_name"
                  type="text"
                  placeholder="Vui lòng nhập tài khoản"
                  {...register("full_name")}
                />
                <span className="error_t">{errors.full_name?.message}</span>
              </div>
            </div>
          )}
          {type === "nick_name" && (
            <div
              className={classNames("form-item", {
                error: errors.nick_name,
              })}
            >
              <div htmlFor="nick_name" className="form_label">
                Tên hiện thị
              </div>
              <div className="form_field">
                <input
                  className="field_control"
                  id="nick_name"
                  type="text"
                  placeholder="Vui lòng nhập tài khoản"
                  {...register("nick_name")}
                />

                <span className="error_t">{errors.nick_name?.message}</span>
              </div>
            </div>
          )}

          {type === "sex" && (
            <div className="menu-items">
              <div className="item">
                <label htmlFor="male">
                  <input
                    type="radio"
                    id="male"
                    name="fav_language"
                    value="male"
                    className="radio_control"
                    {...register("sex")}
                  />
                  <span>Nam</span>
                </label>
              </div>
              <div className="item">
                <label htmlFor="female">
                  <input
                    type="radio"
                    id="female"
                    name="fav_language"
                    value="female"
                    className="radio_control"
                    {...register("sex")}
                  />
                  <span>Nữ</span>
                </label>
              </div>
              <div className="item">
                <label htmlFor="other">
                  <input
                    type="radio"
                    id="other"
                    name="fav_language"
                    value="other"
                    className="radio_control"
                    {...register("sex")}
                  />
                  <span>Chưa biết</span>
                </label>
              </div>
            </div>
          )}
        </form>
        {type === "nick_name" && (
          <p className="note ">Nhập tối đa 8 đến 16 ký tự chữ cái và số</p>
        )}

        {type === "full_name" && (
          <p className="note text-red">
            Để bảo mật tài khoản của bạn,tên thật phải giống với tên của thẻ
            ngân hàng đã được liên kết
          </p>
        )}
      </div>
    </>
  );
});

export default AboutPage;
