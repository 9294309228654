import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

function Panel({ title, children, ...props }) {
  const [open, setOpen] = useState(false);
  return (
    <div className="collapse-item">
      <div className="collapse-title" onClick={() => setOpen(!open)}>
        {title}
        <FontAwesomeIcon
          className="van-icon"
          icon={open ? faAngleDown : faAngleUp}
        />
      </div>
      <div
        className="collapse-wrapper"
        style={{
          display: open ? "block" : "none",
        }}
      >
        <div className="collapse-body">{children}</div>
      </div>
    </div>
  );
}

export default Panel;
