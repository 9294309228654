import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import Collapse from "_common/component/Collapse";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PaymentApi } from "states/api/payment";

const DepositFormPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  const [amount, setAmount] = useState("");
  const [selectAmount, setSelectAmount] = useState("");

  const handleChangeInput = (event) => {
    const value = event?.target?.value;
    const format = value?.replaceAll(",", "")?.replaceAll(".", "");
    setAmount(Number(format));
  };

  const handleSubmit = () => {
    if (amount < Number(general?.min_deposit || 0))
      return NotificationManager.error(
        `Vui lòng nhập số tiền lớn hơn ${formatNumber(general?.min_deposit)}`
      );
    if (amount > Number(general?.max_deposit || 0))
      return NotificationManager.error(
        `Vui lòng nhập số tiền nhỏ hơn ${formatNumber(general?.min_deposit)}`
      );
    navigate(`/payment/deposit/bank-info?money=${amount}`);
    return null;
  };

  const handleSelect = (value) => {
    setSelectAmount(value);
    setAmount(Number(value));
  };

  return (
    <div className="payment-container payment-form-page">
      <div className="payMoney">
        <div className="money">
          <div className="label">
            <span>Số tiền:</span>
          </div>
          <input
            inputMode="numeric"
            pattern="[0-9]"
            placeholder="Nhập số tiền"
            className="control-number"
            style={{ width: "100%" }}
            value={amount ? formatNumber(amount) : ""}
            onChange={handleChangeInput}
          />
        </div>
        <div className="balance">
          <span>Số dư:</span>
          <span className="text-red">{formatNumber(user_wallet?.amount)}</span>
        </div>
        <div className="service">
          <span> Nếu có vấn đề,Vui lòng liên hệ</span>
          <Link to="/support">Dịch vụ trực tuyến</Link>
        </div>
        <button
          type="button"
          className={classNames("btn btn-sub", {
            active: Number(amount || 0) >= Number(general?.min_deposit || 0),
          })}
          onClick={handleSubmit}
        >
          Bước tiếp theo
        </button>
      </div>
    </div>
  );
});

export default DepositFormPage;
