import React from "react";

export default function DepositIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className="antd-mobile-icon"
      style={{ verticalAlign: "-0.125em" }}
    >
      <g
        id="ReceiptOutline-ReceiptOutline"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="ReceiptOutline-编组">
          <rect
            id="ReceiptOutline-矩形"
            fill="#FFFFFF"
            opacity="0"
            x="0"
            y="0"
            width="48"
            height="48"
          />
          <path
            d="M36,4 C39.3137085,4 42,6.6862915 42,10 L42,35.714 L45,35 L45,38 L24,43 L3,38 L3,35 L6,35.714 L6,10 C6,6.6862915 8.6862915,4 12,4 L36,4 Z M36,7 L12,7 C10.4023191,7 9.09633912,8.24891996 9.00509269,9.82372721 L9,10 L9,36.429 L24,40 L39,36.429 L39,10 C39,8.40231912 37.75108,7.09633912 36.1762728,7.00509269 L36,7 Z M29.481684,11.6536905 L29.5554769,11.6868007 L31.4514212,12.7775476 C31.4517209,12.77772 31.4520203,12.7778928 31.4523195,12.778066 L31.5180198,12.8254803 C31.6372256,12.9314105 31.682089,13.0995545 31.6314989,13.2507889 L31.5981516,13.3246307 L26.5546134,22.0374511 L31.1,22.0381798 C31.3209139,22.0381798 31.5,22.2172659 31.5,22.4381798 L31.5,24.6305439 C31.5,24.8514578 31.3209139,25.0305439 31.1,25.0305439 L25.4996134,25.0304511 L25.4996134,27.0254511 L31.1,27.0254532 C31.3209139,27.0254532 31.5,27.2045393 31.5,27.4254532 L31.5,29.6178173 C31.5,29.8387312 31.3209139,30.0178173 31.1,30.0178173 L25.4996134,30.0174511 L25.5,34.6 C25.5,34.8209139 25.3209139,35 25.1,35 L22.9,35 C22.6790861,35 22.5,34.8209139 22.5,34.6 L22.4996134,30.0174511 L16.9,30.0178173 C16.6790861,30.0178173 16.5,29.8387312 16.5,29.6178173 L16.5,27.4254532 C16.5,27.2045393 16.6790861,27.0254532 16.9,27.0254532 L22.4996134,27.0254511 L22.4996134,25.0304511 L16.9,25.0305439 C16.6790861,25.0305439 16.5,24.8514578 16.5,24.6305439 L16.5,22.4381798 C16.5,22.2172659 16.6790861,22.0381798 16.9,22.0381798 L21.3286134,22.0374511 L16.2864814,13.3246307 C16.1758221,13.1334306 16.2411134,12.8887252 16.4323135,12.778066 L16.4327625,12.7778065 L18.329156,11.6868007 C18.5202914,11.5768395 18.764366,11.6423015 18.8748225,11.8331512 L23.9416134,20.5884511 L29.0098105,11.8331512 C29.1064599,11.6661577 29.3054107,11.5951642 29.481684,11.6536905 Z"
            id="ReceiptOutline-形状结合"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
