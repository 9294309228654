import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

function HeaderTitle({
  isBack = true,
  title,
  subTitle,
  isSave,
  isAdd,
  onSubmit,
}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return (
    <div className="home-top-bar">
      {isBack && (
        <span className="btn_back" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </span>
      )}
      <h1 className={classNames("bg_header_name")}>{title}</h1>
      {isSave && (
        <span className="btn_save" onClick={() => onSubmit()}>
          Lưu
        </span>
      )}
      {isAdd && (
        <FontAwesomeIcon
          className="btn_save"
          onClick={() => onSubmit()}
          icon={faPlus}
        />
      )}
      {subTitle && <div className="right">{subTitle}</div>}
    </div>
  );
}

export default HeaderTitle;
