import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";

import { Link, useNavigate } from "react-router-dom";

const CSKHCard = observer(() => {
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  return (
    <div className="recharge-page">
      <div className="top">
        <p>SỐ DƯ: {formatNumber(user_wallet?.amount)}</p>
      </div>
      <div className="btn-wrap">
        <button
          type="button"
          onClick={() => navigate("/support")}
          className="adm-button adm-button-danger adm-button-shape-default"
        >
          <span>Dịch vụ CSKH</span>
        </button>
      </div>
    </div>
  );
});

export default CSKHCard;
