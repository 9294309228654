import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";

const SettingGeneral = observer(() => {
  const {
    authStore: { user, user_wallet },
  } = useStores();

  return (
    <>
      <HeaderTitle title="Cài đặt" />
      <div className="setting-container">
        <div className="menu-items">
          <Link to="/member/set-line" className="item ">
            <div className="left">Chuyển đổi đường dây</div>
            <div className="right">
              <span> Chưa cài đặt</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
});

export default SettingGeneral;
