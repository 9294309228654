import { useMutation } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import React from "react";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import { AuthApi } from "states/api";

import { useStores } from "_common/hooks";

const SupportPage = observer(() => {
  const navigate = useNavigate();
  const {
    authStore: { user, general, clear },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
      navigate("/auth/login");
    },
    onError: (error) => {
      clear();
      const errorMessage = error?.message ?? "Vui lòng thử lại!";
      NotificationManager.error(errorMessage);
    },
  });

  return (
    <div className="support-page">
      <div className="page-content page-content-support">
        {general?.link_support && (
          <iframe
            title="chat_live"
            src={general?.link_support}
            frameBorder="true"
            width="100%"
            height="100%"
          />
        )}
        {user?.status === 0 && (
          <div className="member-footer">
            <button
              type="button"
              className="btn btn-logout"
              onClick={() => onLogout()}
            >
              Đăng Xuất Khỏi Thiết Bị Hiện tại
            </button>
          </div>
        )}
      </div>
    </div>
  );
});

export default SupportPage;
