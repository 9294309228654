import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PaymentApi } from "states/api/payment";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import NoData from "_common/component/NoData";
import {
  paymentStatusColor,
  paymentStatusText,
} from "_common/constants/statusType";
import { useStores } from "_common/hooks";
import { formatCurrency, formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

function ReportHistoryPage() {
  const navigate = useNavigate();
  const {
    authStore: { user },
  } = useStores();

  const [params, setParams] = useState({});
  const { data, refetch } = useQuery(
    ["payment", "list_withdraw", params],
    () =>
      PaymentApi.getList({
        params: {
          type: "withdraw",
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const renderBody = useMemo(() => {
    if (!data?.data?.length) return <NoData description="Không có dữ liệu" />;
    return data?.data?.map((item) => (
      <div
        className="list-item"
        key={item?.id}
        onClick={() => navigate(`/histories/withdraw/detail?id=${item?.id}`)}
      >
        <div className="top">
          <div
            className={classNames("status-text", {
              [paymentStatusColor[item?.status]]: true,
            })}
          >
            Rút tiền {paymentStatusText[item?.status]}
          </div>
        </div>
        <div className="bottom">
          <div className="time">
            {moment(item?.created_at).format("DD/MM/YYYY HH:mm:ss")}
          </div>
          <div className="value">+{formatNumber(item?.amount)}</div>
        </div>
      </div>
    ));
  }, [data]);

  return (
    <>
      <HeaderTitle title="Hồ sơ lợi nhuận" />
      <div className="page-container">
        <div className="win-result">
          <div className="time">{moment().format("DD-MM-YYYY")}</div>
          <div className="total-opiton">
            <div>TỔNG VỐN ĐẦU TƯ：</div>
            <span>{formatCurrency(user?.total_fund)}</span>
          </div>
          <div className="total-opiton">
            <div>TỔNG LỢI NHUẬN TRONG NGÀY</div>
            <span>{formatCurrency(user?.bill_commission_today)}</span>
          </div>
          <div className="total-opiton">
            <div>TỔNG LỢI NHUẬN ĐÃ NHẬN</div>
            <span>{formatCurrency(user?.bill_commission)}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportHistoryPage;
