import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { useStores } from "_common/hooks";
import classNames from "classnames";
import InputPassword from "_common/component/InputPassword";

const schema = yup
  .object({
    username: yup
      .string()
      .min(6, "Vui lòng nhập tên người dùng lớn hơn 6 ký tự")
      .max(12, "Vui lòng nhập tên người dùng nhỏ hơn 12 ký tự")
      .required("Vui lòng nhập tên người dùng"),
    password: yup.string().required("Vui lòng nhập mật khẩu"),
    confirmed: yup
      .string()
      .required("Vui lòng nhập lại mật khẩu mới")
      .oneOf([yup.ref("password")], "Mật khẩu không khớp"),
    ref_no: yup.string(),
  })
  .required();

export default function RegisterForm() {
  const { authStore } = useStores();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.register(variables),
    {
      onSuccess: (res) => {
        authStore.updateToken(res?.data);
        authStore.updateUser(res?.data);
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleRegister = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  return (
    <form className="auth-form-user" onSubmit={handleSubmit(handleRegister)}>
      <div>
        <ul>
          <li
            className={classNames("marginBottom", {
              error: errors.username,
            })}
          >
            <div className="form_In">
              <input
                className="field_control"
                id="username"
                type="text"
                placeholder="Vui lòng nhập tên người dùng"
                {...register("username")}
              />
              <span className="error_t">{errors.username?.message}</span>
            </div>
          </li>
          <li
            className={classNames("marginBottom", {
              error: errors.password,
            })}
          >
            <div className="form_In">
              <InputPassword
                className="field_control"
                id="password"
                placeholder="Vui lòng nhập mật khẩu"
                {...register("password")}
              />
              <span className="error_t">{errors.password?.message}</span>
            </div>
          </li>
          <li
            className={classNames("marginBottom", {
              error: errors.confirmed,
            })}
          >
            <div className="form_In">
              <InputPassword
                className="field_control"
                id="confirmed"
                placeholder="Vui lòng xác nhận mật khẩu"
                {...register("confirmed")}
              />
              <span className="error_t">{errors.confirmed?.message}</span>
            </div>
          </li>
          <li
            className={classNames("marginBottom", {
              error: errors.ref_no,
            })}
          >
            <div className="form_In">
              <input
                className="field_control"
                id="ref_no"
                type="text"
                placeholder="Vui lòng mã giới thiệu"
                {...register("ref_no")}
              />
              <span className="error_t">{errors.ref_no?.message}</span>
            </div>
          </li>
        </ul>
        <p className="signin-other-title">
          Bạn đã có tài khoản? <Link to="/auth/login">Đăng nhập</Link>
        </p>
        <div className="form_button">
          <button type="submit" className="btn btn-submit">
            Đăng ký
          </button>
        </div>
        <div className="forgot-pass" onClick={() => navigate("/")}>
          <span>Quay về trang chủ</span>
        </div>
      </div>
    </form>
  );
}
