import React from "react";

export default function HomeIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className="antd-mobile-icon"
      style={{ verticalAlign: "-0.125em" }}
    >
      <g
        id="AppOutline-AppOutline"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="AppOutline-编组">
          <rect
            id="AppOutline-矩形"
            fill="#FFFFFF"
            opacity="0"
            x="0"
            y="0"
            width="48"
            height="48"
          />
          <path
            d="M38.6673097,11.5277497 L27.3505965,4.90817356 C25.2828181,3.69727548 22.7306145,3.69727548 20.6583507,4.90817356 L9.33715206,11.5277497 C7.2693737,12.7386478 6,14.9675973 6,17.3804238 L6,30.6195762 C6,33.0324027 7.27385912,35.265837 9.33715206,36.4722503 L20.6538653,43.0918264 C22.7261291,44.3027245 25.2738473,44.3027245 27.3461111,43.0918264 L38.6628243,36.4722503 C40.7306027,35.265837 41.9999882,33.0324027 41.9999882,30.6195762 L41.9999882,17.3849086 C42.0044618,14.9675973 40.7350881,12.7386478 38.6673097,11.5277497 Z M8.62397037,17.3804238 C8.62397037,15.8914677 9.40443335,14.5191165 10.673807,13.7746384 L21.9591223,7.16851665 C23.2240106,6.42852338 24.7804512,6.42852338 26.0453394,7.16851665 L32.4908872,10.9447247 L12.9479147,22.3764996 L8.62397037,19.8470681 L8.62397037,17.3804238 Z M23.7398339,19.0622267 L32.4729455,24.1704227 L24.2466863,28.9826214 L15.5135746,23.8744254 L23.7398339,19.0622267 L23.7398339,19.0622267 Z M10.673807,34.1984527 C9.40443335,33.4584595 8.62397037,32.0816235 8.62397037,30.5926673 L8.62397037,22.8474044 L21.6810263,30.485032 L13.0017397,35.5618343 L10.673807,34.1984527 L10.673807,34.1984527 Z M39.3804914,30.5926673 C39.3804914,32.0816235 38.6000284,33.4539747 37.3306547,34.1984527 L26.0453394,40.8045745 C24.7804512,41.5445678 23.2240106,41.5445678 21.9591223,40.8045745 L15.5629142,37.0597601 L35.0386054,25.6683485 L39.3804914,28.2067496 L39.3804914,30.5926673 L39.3804914,30.5926673 Z M39.3804914,25.2108981 L35.6486224,23.0267967 L26.3054938,17.5643009 L35.0565471,12.4471353 L37.3306547,13.7791232 C38.6000284,14.5191165 39.3804914,15.8959525 39.3804914,17.3849086 L39.3804914,25.2108981 L39.3804914,25.2108981 Z"
            id="AppOutline-形状"
            fill="currentColor"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  );
}
