import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { useStores } from "_common/hooks";

import MobileFooter from "../Footer/MobileFooter";

export default function MobileLayout() {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="mobile-layout">
      <div className="tab-layout">
        <Outlet />
        <MobileFooter />
      </div>
    </div>
  );
}
