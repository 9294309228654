import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { BankApi } from "states/api/bank";
import { get } from "lodash";
import { NotificationManager } from "react-notifications";

const BankPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  const { data, refetch } = useQuery(
    ["bank", "list"],
    () =>
      BankApi.getList({
        params: {
          page: 1,
          limit: 10,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const { mutate, isLoading } = useMutation((id) => BankApi.delete({ id }), {
    onSuccess: (res) => {
      refetch();
      NotificationManager.success(res?.msg || "Thao tác thành công");
    },
    onError: (error) => {
      const errorMessage = error?.message ?? "Vui lòng thử lại!";
      NotificationManager.error(errorMessage);
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <HeaderTitle
        title="Chi tiết rút tiền"
        isAdd
        onSubmit={() => navigate("//payment/bank/add")}
      />

      <div className="payment-container bank-page">
        <div className="navbar-container">
          <h3 className="page-title">Thẻ ngân hàng</h3>
        </div>
        {get(data, "data", [])?.length > 0 ? (
          get(data, "data", [])?.map((item) => (
            <div className="card-info" key={item?.id}>
              <p>
                <span>{get(item, "bank_owner")}</span>
                <span className="text-red" onClick={() => mutate(item?.id)}>
                  Xoá
                </span>
              </p>
              <p>{get(item, "bank_name")}</p>
              <p>{get(item, "bank_number_display")}</p>
            </div>
          ))
        ) : (
          <Link to="/payment/bank/add" className="add-card">
            <FontAwesomeIcon icon={faPlus} />
            <span>Thêm thẻ ngân hàng</span>
          </Link>
        )}
      </div>
    </>
  );
});

export default BankPage;
