import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { PaymentApi } from "states/api/payment";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import {
  gameBetItemType,
  gameLevelText,
  gameStatusText,
  gameTypeText,
  paymentStatusText,
} from "_common/constants/statusType";
import { formatNumber } from "_common/utils/formatValue";
import { GameApi } from "states/api/game";

function GameHistoryDetailPage() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const id = params.get("id");

  const { data, refetch } = useQuery(
    ["game", "detail", id],
    () => GameApi.getDetail({ id }),
    {
      staleTime: 300000,
      id,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const renderResult = useMemo(() => {
    if (!data) return <span />;
    const game = data?.game;
    return (
      <div>
        {game?.number1} , {game?.number2} , {game?.number3} , {game?.number4} ,{" "}
        {game?.number5} | {game?.total_number}
      </div>
    );
  }, [data]);

  return (
    <>
      <HeaderTitle title="Chi tiết giao dịch" />
      <div className="container_main">
        <div className="histories ">
          <div className="main-info">
            <div className="item">
              <span className="label">Số kỳ:</span>
              <span className="text">{data?.game?.game_no}</span>
            </div>
            <div className="item">
              <span className="label">Thời gian giao dịch:</span>
              <span className="text">
                {moment(data?.created_at).format("DD/MM/YYYY HH:mm:ss")}
              </span>
            </div>
            <div className="item">
              <span className="label">Loại hàng:</span>
              <span className="text">{gameLevelText[data?.game?.level]}</span>
            </div>
            <div className="item">
              <span className="label">Hình thức:</span>
              <span className="text">
                {gameTypeText[data?.game_bet_type]} |{" "}
                {gameBetItemType[data?.game_bet]}
              </span>
            </div>
            {/* <div className="item">
              <span className="label">Trạng thái:</span>
              <span className="text">{gameStatusText[data?.status]}</span>
            </div> */}
            <div className="item">
              <span className="label">Số tiền giao dịch:</span>
              <span className="text">{formatNumber(data?.amount)}</span>
            </div>
            <div className="item">
              <span className="label">Phần thưởng:</span>
              <span className="text">{formatNumber(data?.amount_payback)}</span>
            </div>
            <div className="item">
              <span className="label">Mã hàng:</span>
              <span className="text">{renderResult}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GameHistoryDetailPage;
