import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import { positionKeyText } from "_common/constants/statusType";

const SettingPage = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage = error?.message ?? "Vui lòng thử lại!";
      NotificationManager.error(errorMessage);
    },
  });
  return (
    <>
      <HeaderTitle title="Cài đặt" />
      <div className="setting-container">
        <div className="menu-items">
          <Link to="/member/setting" className="item ">
            <div className="left">Đăng nhập tài khoản</div>
            <div className="right">{user?.username}</div>
          </Link>
          <Link to="/member/setting" className="item ">
            <div className="left">Kiểu người dùng</div>
            <div className="right">{positionKeyText[user?.position_key]}</div>
          </Link>
          <Link to="/member/change-password" className="item ">
            <div className="left">Mật khẩu đăng nhập</div>
            <div className="right">
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          {/* <Link to="/member/change-wallet-password" className="item ">
            <div className="left">Mật khẩu rút tiền</div>
            <div className="right">
              {!user?.wallet_password && <span>Chưa cài đặt</span>}
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link> */}
          <Link to="/member/information" className="item ">
            <div className="left">Tư liệu hoàn chỉnh</div>
            <div className="right">
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
        </div>
        <div className="member-footer">
          <button
            type="button"
            className="btn btn-logout"
            onClick={() => onLogout()}
          >
            Đăng Xuất Khỏi Tài Khoản Hiện Tại
          </button>
        </div>
      </div>
    </>
  );
});

export default SettingPage;
