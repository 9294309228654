import React, { useEffect, useState } from "react";
import Panel from "./Panel";

function Collapse({ children, ...props }) {
  return <div className="collapses">{children}</div>;
}

Collapse.Panel = Panel;

export default Collapse;
