import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";

export default function AboutPage() {
  return (
    <>
      <HeaderTitle title="Về chúng tôi" />
      <div className="update-info-container">
        <div className="content">
          <p>
            - Hàng quý chúng tôi luôn có những món quà thưởng và sẽ có quay
            trúng thưởng dành cho các khách hàng may mắn. Chiết khấu % tốt và %
            giảm giá dành cho khách hàng VIP thường xuyên mua sắm tại cửa hàng,
          </p>
          <p>
            - Miễn phí vận chuyển khi quý khách mua hàng online có giá trị từ
            3.000.000 trở lên .
          </p>
          <p>
            - Đổi trả miễn phí trong vòng 3 ngày khi phát hiện sản phẩm có lỗi
            từ NSX.
          </p>
          <p>
            - ĐẶC BIỆT : Ngoài ra chúng tôi cũng có nhiều ưu đãi dành cho các
            CTV hỗ trợ tăng doanh thu cho các sản phẩm. Hoa hồng % sẽ dựa vào
            lượt tương tác với sản phẩm và doanh thu các bạn mang lại.
          </p>
          <p>
            Chúng tôi có những chương trình tri ân ctv online vào những dịp lễ
            tết lên đến 1 tỷ đồng cho ctv.
          </p>
          <p>
            <i>( Lưu ý: Tri ân chỉ dành cho ctv online )</i>
          </p>
          <p>
            - Mọi thắc mắc và khiếu nại xin liên hệ CSKH online để được hỗ trợ
            nhanh và tốt nhất .
          </p>
        </div>
      </div>
    </>
  );
}
