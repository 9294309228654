import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";

import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import CSKHCard from "./CSKHCard";
import DepositFormPage from "./DepositFormPage";

const DepositPage = observer(() => {
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  if (Number(general?.deposit_cskh) === 1)
    return (
      <>
        <HeaderTitle isBack title="Nạp tiền" />
        <div className="page-container">
          <CSKHCard />
        </div>
      </>
    );

  return (
    <>
      <HeaderTitle isBack title="Nạp tiền" />
      <DepositFormPage />
    </>
  );
});

export default DepositPage;
