import React, { useEffect, useState } from "react";
import { get } from "lodash";

import { useStores } from "_common/hooks";
import classNames from "classnames";
import { GameApi } from "states/api/game";
import { useQuery } from "@tanstack/react-query";

function PromotionPage() {
  const {
    authStore: { games },
  } = useStores();

  const [activeTab, setActiveTab] = useState(get(games[0], "room_id"));

  const { data: listData, refetch } = useQuery(
    ["game", "list", activeTab],
    () =>
      GameApi.getGames({
        params: {
          page: 1,
          limit: 20,
          status: "finish",
          level: activeTab,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="game-page">
      <div className="board-sticky">
        <div className="game-navbar">
          <div className="nav-item">
            <b>Lịch sử mã hàng</b>
          </div>
        </div>
        <div className="level">
          {games?.map((item) => (
            <div
              className={classNames("level__item game_type_0 ", {
                active: activeTab === item?.room_id,
              })}
              key={item?.room_id}
              onClick={() => setActiveTab(item?.room_id)}
            >
              {item?.name}
            </div>
          ))}
        </div>
      </div>
      <div className="list-content page-content">
        <div id="promotions">
          <div className="trend__result active">
            <div className="trend__result-heading">
              <div className="trend__result-heading-item">Số kỳ</div>
              <div className="trend__result-heading-item">Mã hàng</div>
            </div>
            <div className="trend__result-content">
              {listData?.data?.map((item) => (
                <div className="trend__result-content-row" key={item?.id}>
                  <div className="trend__result-content-no">
                    {item?.game_no}
                  </div>
                  <div className="trend__result-items">
                    <div className="trend__result-item">
                      <span>{item?.number1}</span>
                    </div>
                    <div className="trend__result-item">
                      <span>{item?.number2}</span>
                    </div>
                    <div className="trend__result-item">
                      <span>{item?.number3}</span>
                    </div>
                    <div className="trend__result-item">
                      <span>{item?.number4}</span>
                    </div>
                    <div className="trend__result-item">
                      <span>{item?.number5}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromotionPage;
