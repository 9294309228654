import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";

export default function ContactPage() {
  return (
    <>
      <HeaderTitle title="Liên hệ" />
      <div className="update-info-container">
        <h5>Version: 1.0.0</h5>
      </div>
    </>
  );
}
