import React, { useState } from "react";
import { useStores } from "_common/hooks";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import PageLoading from "_common/component/PageLoading";
import { observer } from "mobx-react-lite";
import NotifyProvider from "_common/component/NotifyProvider";
import MobileLayout from "./MobileLayout";

const BasicLayout = observer(() => {
  const { authStore } = useStores();

  if (!authStore.logo) return <PageLoading />;
  if (!authStore.token) return <PageLoading />;
  if (!authStore.user?.id) return <PageLoading />;

  return (
    <NotifyProvider>
      <MobileLayout />
    </NotifyProvider>
  );
});

export default BasicLayout;
