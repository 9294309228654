import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PaymentApi } from "states/api/payment";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import NoData from "_common/component/NoData";
import {
  gameBetItemType,
  gameLevelText,
  gameStatusText,
  gameTypeText,
} from "_common/constants/statusType";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { GameApi } from "states/api/game";

function GameHistoryPage() {
  const navigate = useNavigate();

  const [params, setParams] = useState({});
  const { data, refetch } = useQuery(
    ["game", "list", params],
    () =>
      GameApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const renderBody = useMemo(() => {
    if (!data?.data?.length) return <NoData description="Không có dữ liệu" />;
    return data?.data?.map((item) => (
      <div
        className="item"
        key={item?.id}
        onClick={() => navigate(`/histories/game/detail?id=${item?.id}`)}
      >
        <div className="left">
          <div className="title">
            {gameTypeText[item?.game_bet_type]} |{" "}
            {gameBetItemType[item?.game_bet]}
          </div>
          <div className="des">
            Loại hàng: {gameLevelText[item?.game?.level]}
          </div>
          <div className="des">Kỳ: {item?.game?.game_no}</div>
          <div className="des">Số tiền: {formatNumber(item?.amount)}</div>
          <div className="des">
            Phần thưởng: {formatNumber(item?.amount_payback)}
          </div>
          <div className="time">
            <span>
              {moment(item?.created_at).format("DD/MM/YYYY HH:mm:ss")}
            </span>
          </div>
        </div>
        <div className="right">
          {/* <span className="status">{gameStatusText[item?.status]}</span> */}
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
      </div>
    ));
  }, [data]);

  return (
    <>
      <HeaderTitle title="Lịch sử giao dịch" />
      <div className="container_main">
        <div className="histories ">{renderBody}</div>
      </div>
    </>
  );
}

export default GameHistoryPage;
