import moment from "moment";
import { NotificationManager } from "react-notifications";

export function getUniqueId(prefix, length = 6) {
  let number_no = "";
  const now = moment();

  const timestamp = now.unix().toString();

  number_no = timestamp.substr(timestamp.length - 5, timestamp.length - 1);
  return `${prefix}${number_no}`;
}

const selectText = (editableEl, selectionStart, selectionEnd) => {
  const isIOS = navigator.userAgent.match(/ipad|ipod|iphone/i);
  if (isIOS) {
    const range = document.createRange();
    range.selectNodeContents(editableEl);

    const selection = window.getSelection(); // current text selection
    selection.removeAllRanges();
    selection.addRange(range);
    editableEl.setSelectionRange(selectionStart, selectionEnd);
  } else {
    editableEl.select();
  }
};

const copyToClipboard = (value) => {
  const el = document.createElement("textarea");
  el.value = value;

  el.style.position = "absolute";
  el.style.left = "-9999px";
  el.readOnly = true;
  el.contentEditable = "true";

  document.body.appendChild(el);

  selectText(el, 0, value.length);

  document.execCommand("copy");
  NotificationManager.info("Đã copy");
  document.body.removeChild(el);
};

export const handleCopyLink = (value) => {
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        NotificationManager.info("Đã copy");
      })
      .catch(() => {
        NotificationManager.error("Lỗi khi copy");
      });
  } else {
    copyToClipboard(value);
  }
};
