import React from "react";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import NoData from "_common/component/NoData";

function AccountHistoryPage() {
  return (
    <>
      <HeaderTitle title="Thông tin tài khoản" />
      <div className="container_main">
        <div className="histories ">
          <NoData description="Không có dữ liệu" />
        </div>
      </div>
    </>
  );
}

export default AccountHistoryPage;
