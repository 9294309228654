import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MediaQueryProvider from "_common/component/MediaQueryProvider";
import { NotificationContainer } from "react-notifications";

import App from "./App";

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <MediaQueryProvider>
      <App />
      <NotificationContainer />
    </MediaQueryProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);

Notification.requestPermission();
